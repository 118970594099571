<template>
  <b-container
    v-if="exerciseMovement"
    class="movement-route-lesson-detail-page py-6"
    fluid
  >
    <b-row>
      <b-col lg="3" xl="2">
        <div
          class="card card-custom gutter-b movement-route-lesson-detail-page__left-panel"
        >
          <div class="card-body p-0">
            <b-card
              overlay
              class="movement-route-lesson-detail-page__left-panel__image-card"
              :img-src="exerciseMovement.image.url"
            >
              <b-card-text
                class="movement-route-lesson-detail-page__left-panel__image-card-title"
              >
                {{ exerciseMovement.name }}
              </b-card-text>
            </b-card>
            <div class="movement-route-lesson-detail-page__left-panel__actions">
              <b-button
                class="mr-4 btn-edit"
                size="sm"
                @click="onClickEditButton"
              >
                <span class="svg-icon svg-icon-sm mr-0">
                  <inline-svg
                    class="svg-icon mr-0"
                    src="/media/svg/icons/Neolex/Basic/edit-2.svg"
                  />
                </span>
              </b-button>
            </div>
            <div
              class="movement-route-lesson-detail-page__left-panel__information"
            >
              <b-container>
                <b-row>
                  <b-col>
                    <div class="text-muted">Mã bài tập</div>
                    <div>{{ exerciseMovement.code }}</div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <div class="text-muted">Số luượng nhóm động tác</div>
                    <div>
                      <span class="svg-icon svg-icon-xl">
                        <inline-svg
                          class="svg-icon"
                          src="/media/svg/icons/Neolex/Basic/Library.svg"
                        />
                      </span>
                      {{ exerciseMovement.sections.length }}
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <div class="text-muted">Mô tả</div>
                    <div>
                      {{ exerciseMovement.description }}
                    </div>
                  </b-col>
                </b-row>
              </b-container>
            </div>
          </div>
        </div>
      </b-col>
      <b-col lg="9" xl="10">
        <div
          class="card card-custom gutter-b movement-route-lesson-detail-page__right-panel"
        >
          <div class="card-body p-0">
            <b-tabs
              active-tab-class="p-0"
              card
              class="movement-route-lesson-detail-page__right-panel__tabs"
            >
              <b-tab>
                <template #title>
                  <span>Chi tiết bài tập</span>
                </template>
                <div class="p-5">
                  <movement-route-lesson-detail-table
                    :data="exerciseMovement.sections"
                    @sortBy="sortBy"
                    @edit="onClickEditButton"
                  />
                </div>
              </b-tab>
              <b-tab>
                <template #title>
                  <span>Thống kê đánh giá</span>
                </template>
                <div class="p-5">
                  <MovementRouteLessonReview />
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { orderBy } from 'lodash';
export default {
  name: 'MovementRouteLessonDetail',
  components: {
    MovementRouteLessonDetailTable: () =>
      import('./components/MovementRouteLessonDetailTable'),
    MovementRouteLessonReview: () =>
      import('./components/MovementRouteLessonReview'),
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    lessonId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      exerciseMovement: null,
      sortby: {
        by: null,
        order: null,
      },
    };
  },
  created() {
    this.getExerciseMovement();
  },
  methods: {
    onClickEditButton() {
      this.$router.push({
        name: 'movement_route_lesson_edit',
        params: {
          id: this.id,
          lessonId: this.lessonId,
        },
      });
    },
    async getExerciseMovement() {
      try {
        const { meta, data } = await this.$api.get(
          `/ExerciseMovement/${this.lessonId}`,
        );
        if (!meta.success) return;
        this.exerciseMovement = data;
      } catch (error) {
        return this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      }
    },
    sortBy(val) {
      this.exerciseMovement.sections = orderBy(
        this.exerciseMovement.sections,
        [val.column],
        [val.order],
      );
    },
  },
};
</script>

<style lang="scss">
.movement-route-lesson-detail-page {
  .movement-route-lesson-detail-page__left-panel {
    height: 100%;

    .movement-route-lesson-detail-page__left-panel__image-card {
      margin-bottom: 20px;
      min-height: 140px;

      .card-body {
        padding: 0;
        min-height: 140px;
      }

      .movement-route-lesson-detail-page__left-panel__image-card-title {
        height: 60px;
        width: 100%;
        position: absolute;
        bottom: 0;
        background: rgba(0, 0, 0, 0.7);
        font-weight: bold;
        font-size: 16px;
        color: #ffffff;
        padding: 10px;
        overflow: hidden;
      }
    }

    .movement-route-lesson-detail-page__left-panel__actions {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      padding: 0 20px;

      .movement-route-lesson-detail-page__left-panel__actions__button {
        height: 30px;
        width: 30px;

        .movement-route-lesson-detail-page__left-panel__actions__button__icon {
          height: 12px;
          width: 12px;
        }
      }
    }
  }

  .movement-route-lesson-detail-page__right-panel {
    height: 100%;
  }
}

.movement-route-lesson-detail-page__right-panel__tabs {
  a.nav-link {
    border: 0;

    &.active {
      font-weight: 600;
      color: #01645a !important;
      border-bottom: 2px solid #01645a;
    }
  }

  li.nav-item {
    a.nav-link:hover {
      color: #333;
    }
  }
}

.btn-edit {
  background-color: #def2f1 !important;
  border-color: #def2f1 !important;
  color: #008479 !important;
}
</style>
